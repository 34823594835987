<template>
  <div class="boss">
    <!-- <van-nav-bar :title="name" left-arrow fixed @click-left="onClickLeft" /> -->
    <div class="home">
      <iframe
        ref="iframe"
        src="https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BANEJK1olXDYDZBoCUBVIMzZNXhpXVhgcDwYCXhxDXHBTTkRHA1ocDBsJVEVTbT9aXjVUUUJdDAACFBtFRjdPQx5dSkJdDAACZgpHVTtmQw4ZXgcCV1ptbAxiXRdYTlx1Axx_CB4JChhkUy4KTVcZbQMCVV9YDkseB2o4G1ITXQYyZF5eOAB5Al8KGloXWwEGXVlaOEsQB2kJHV8XXgMDXFhtD0seMylRWQJFAk1bBAZtOHsUM2gIEk8TL0dQQFgvOHsUA18LKwRRAFsyVl9cDEInMw"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xieyi: '',
      name: '第三方京东',
    }
  },
  created() {},
  methods: {
    onClickLeft() {
      // console.log(12);
      if (this.$route.query.planform == 'h5') {
        history.go(-1)
      } else {
        window.location.href = 'uniwebview://Close?test=Close'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  // height: 100vh;
  iframe {
    width: 100%;
    // position: absolute;
    height: 100vh;
    z-index: 1;
  }
}
.boss {
  min-height: 100vh;
  width: 100vw;
  .van-nav-bar {
    height: 1rem;
    line-height: 1rem;
    /deep/ .van-nav-bar__content {
      height: 1rem;
    }
    /deep/ .van-nav-bar__title {
      font-size: 0.4rem;
    }
    /deep/ .van-icon {
      font-size: 0.5rem;
      color: #999;
    }
  }
  .content {
    padding: 0.85rem 0.3rem;
  }
}
</style>
